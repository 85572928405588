import axios from "axios";

const api = {
  state() {
    return {
      url: process.env.VUE_APP_ROOT_API,
    };
  },
  getters: {
    url(state) {
      return state.url;
    },
  },
  actions: {
    async getRequest(context, { api }) {
      return await axios
        .get(`${context.state.url}${api}`)
        .then((res) => {
          return {
            success: true,
            data: res.data,
            useCookies: context.rootState.cookies.useCookies,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error?.response?.data?.message,
          };
        });
    },
    async postRequest(context, { api, body }) {
      return await axios
        .post(`${context.state.url}${api}`, body)
        .then((res) => {
          return {
            success: true,
            data: res.data,
            useCookies: context.rootState.cookies.useCookies,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error?.response?.data?.message,
          };
        });
    },
    async formRequest(context, { api, form }) {
      return await axios
        .post(`${context.state.url}${api}`, form, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          return {
            success: true,
            data: res.data,
            useCookies: context.rootState.cookies.useCookies,
          };
        })
        .catch((error) => {
          return {
            success: false,
            error: error?.response?.data?.message,
          };
        });
    },
  },
};

export default api;
