const cookies = {
  state() {
    return {
      useCookies: !!localStorage.getItem("cookie"),
    };
  },
  getters: {
    useCookies(state) {
      return state.useCookies;
    },
  },
  mutations: {
    setCookies(state) {
      state.useCookies = true;
      localStorage.setItem("cookie", true);
    },
  },
  actions: {
    acceptCookies(context) {
      context.commit("setCookies");
      localStorage.setItem("map", JSON.stringify(context.rootState.footer.map));
      localStorage.setItem(
        "contactInfo",
        JSON.stringify(context.rootState.footer.contactInfo)
      );
      localStorage.setItem(
        "icons",
        JSON.stringify(context.rootState.footer.icons)
      );
      localStorage.setItem(
        "languages",
        JSON.stringify(context.rootState.languages.languages)
      );
      localStorage.setItem(
        "language",
        JSON.stringify(context.rootState.languages.language)
      );
      localStorage.setItem(
        "navigation",
        JSON.stringify(context.rootState.navigation.navigation)
      );
      localStorage.setItem(
        "logo",
        JSON.stringify(context.rootState.navigation.logo)
      );
      if (process.env.VUE_APP_TYPE === "PK") {
        context.dispatch("saveBuilderData");
        context.dispatch("saveContactData")
      }
    },
  },
};

export default cookies;
