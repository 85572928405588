const navigation = {
  state() {
    return {
      navigation: [],
      logo: require("@/assets/logo.png"),
    };
  },
  getters: {
    navigation(state) {
      try {
        return localStorage.getItem("navigation") != null
          ? JSON.parse(localStorage.getItem("navigation"))
          : state.navigation;
      } catch (error) {
        return state.navigation;
      }
    },
    logo(state) {
      try {
        return localStorage.getItem("logo") != null
          ? JSON.parse(localStorage.getItem("logo"))
          : state.logo;
      } catch (error) {
        return state.logo;
      }
    },
  },
  mutations: {
    setNavigation(state, navigation) {
      state.navigation = navigation;
    },
    setLogo(state, logo) {
      state.logo = logo;
    },
  },
  actions: {
    getNavigation(context) {
      context
        .dispatch("getRequest", {
          api: "web/navigation",
        })
        .then((res) => {
          context.commit("setNavigation", res.data);
          if (res.useCookies) {
            localStorage.setItem("navigation", JSON.stringify(res.data));
          }
        });
    },
    getLogo(context) {
      context
        .dispatch("getRequest", {
          api: "logo",
        })
        .then((res) => {
          context.commit("setLogo", res.data.imageUrl);
          if (res.useCookies) {
            localStorage.setItem("logo", JSON.stringify(res.data.imageUrl));
          }
        });
    },
  },
};

export default navigation;
