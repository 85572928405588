<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content
        ? `${content} | Quality Electrical Systems`
        : `Quality Electrical Systems`
    }}</template>
  </metainfo>
  <v-app>
    <Sidebar :sidebar="sidebar" @close-sidebar="toggleSidebar()" />
    <Header
      class="elevation-0"
      style="transition: 0.2s all ease-out"
      @toggle-sidebar="toggleSidebar()"
    />
    <v-main style="min-height: 100vh">
      <router-view :key="$route.fullPath" ref="router" />
    </v-main>
    <additional-pages />
    <Footer />
    <div id="google_translate_element"></div>
    <vue-simple-context-menu
      element-id="vue-simple-context-menu"
      :options="contextMenuOptions"
      ref="vueSimpleContextMenu"
      @option-clicked="contextMenuActions"
    />
    <Cookies />
  </v-app>
</template>

<script>
import Header from "./components/app/Header.vue";
import Sidebar from "./components/app/Sidebar.vue";
import Footer from "./components/app/Footer.vue";
import AdditionalPages from "./components/app/AdditionalPages.vue";
import Cookies from "./components/app/Cookies.vue";
import { mapActions, mapGetters } from "vuex";
import { useMeta } from "vue-meta";

export default {
  name: "App",

  components: {
    Header,
    Sidebar,
    Footer,
    AdditionalPages,
    Cookies,
  },

  setup() {
    useMeta({
      title: "",
      htmlAttrs: { lang: "en", amp: true },
      description: "State of the art electrical manufacturing",
      keywords: [
        "Enclosure",
        "Switchgear",
        "SWG",
        "VFD",
        "RVSS",
        "E-House",
        "PDC",
        "EDC",
        "Custom",
        "Softstarter",
        "Medium",
        "Voltage",
        "Variable",
        "Frequency",
        "Drive",
        "Low",
        "MCC",
        "Control",
        "Panel",
        "Adjustable",
        "Speed",
        "Motor",
        "Starter",
      ],
    });
  },

  async created() {
    this.$gtag.event("visit", { method: "Google" });
    this.getNavigation();
    this.getContactInfo();
    this.getIcons();
    this.getLogo();
    this.getLanguages();
    this.changeLanguage(this.language);
    this.setDisplaySizes();
    if (process.env.VUE_APP_TYPE === "WEB") {
      this.getMap();
    } else if (process.env.VUE_APP_TYPE === "PK") {
      this.getHorsepowerRange();
      this.getJobQuestions();
      this.getLegalStatements();
    }
  },

  data() {
    return {
      sidebar: false,
      headerElevate: false,
      contextMenuOptions: [],
    };
  },
  mounted() {
    const style = document.documentElement.style;
    const colors = this.$vuetify.theme.current.colors;
    Object.keys(colors).forEach((color) => {
      style.setProperty(`--${color}`, colors[color]);
    });
    this.googleTranslateElementInit();
  },
  computed: {
    ...mapGetters(["language"]),
  },
  watch: {
    "$vuetify.display.lgAndUp"() {
      this.setLgAndUp(this.$vuetify.display.lgAndUp);
    },
    "$vuetify.display.md"() {
      this.setMd(this.$vuetify.display.md);
    },
    "$vuetify.display.sm"() {
      this.setSm(this.$vuetify.display.sm);
    },
    "$vuetify.display.xs"() {
      this.setXs(this.$vuetify.display.xs);
    },
    "$vuetify.display.mobile"() {
      this.setMobile(this.$vuetify.display.mobile);
    },
  },
  methods: {
    ...mapActions([
      "getNavigation",
      "getMap",
      "getContactInfo",
      "getIcons",
      "getLogo",
      "getLanguages",
      "setLanguage",
      "setLgAndUp",
      "setMd",
      "setSm",
      "setXs",
      "setMobile",
      "getHorsepowerRange",
      "getJobQuestions",
      "getLegalStatements"
    ]),
    setDisplaySizes() {
      this.setLgAndUp(this.$vuetify.display.lgAndUp);
      this.setMd(this.$vuetify.display.md);
      this.setSm(this.$vuetify.display.sm);
      this.setXs(this.$vuetify.display.xs);
      this.setMobile(this.$vuetify.display.mobile);
    },
    defaultImage(event) {
      event.src = require("@/assets/preview.png");
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    googleTranslateElementInit() {
      window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          includedLanguages: "en,es,fr,ar",
          layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        },
        "google_translate_element"
      );
    },
    changeLanguage(language) {
      this.setLanguage(language);
      const frames = document.querySelectorAll("iframe.skiptranslate");
      if (language === "English") {
        [...frames].every((frame) => {
          if (frame.id.includes("container")) {
            const buttons =
              frame.contentWindow.document.getElementsByTagName("button");
            return [...buttons].every((button) => {
              if (button.id.includes("restore")) {
                button.click();
                return false;
              }
              return true;
            });
          }
          return true;
        });
      } else {
        [...frames].every((frame) => {
          if (!frame.id.includes("container")) {
            const labels =
              frame.contentWindow.document.getElementsByClassName("text");
            return [...labels].every((label) => {
              if (label.innerText === language) {
                label.parentElement.parentElement.click();
                return false;
              }
              return true;
            });
          }
          return true;
        });
      }
    },
    openContextMenu(e, index, type) {
      var parameters = {};

      this.contextMenuOptions = [];
      this.contextMenuOptions.push({
        name: `Copy URL for ${this.typeToString(type)}`,
      });
      parameters.scroll = `${type}-${index}`;

      var range = "";
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        range = selection.getRangeAt(0);
        if (range.toString() != "") {
          this.contextMenuOptions.push({ name: "Copy Highlighted Text" });
          parameters.copyText = range.toString();
        }
      }

      this.$refs.vueSimpleContextMenu.showMenu(e, parameters);
    },
    contextMenuActions(e) {
      const option = e.option.name;
      switch (option) {
        case "Copy Highlighted Text":
          navigator.clipboard.writeText(e.item.copyText);
          break;
        default:
          var url = !this.$route.query.scroll
            ? location.href
            : location.href.substring(0, location.href.lastIndexOf("?"));
          if (url[url.length - 1] != "/") url += "/";
          url += "?scroll=" + e.item.scroll;
          navigator.clipboard.writeText(url);
          break;
      }
    },
    typeToString(type) {
      const words = type.split("_");
      return words
        .map((word) => word[0].toUpperCase() + word.substr(1))
        .join(" ");
    },
  },
};
</script>

<style>
:root {
  --swiper-theme-color: var(--pop) !important;
  --swiper-pagination-bullet-inactive-color: rgb(0, 0, 0) !important;
  --swiper-pagination-bullet-width: 12px !important;
  --swiper-pagination-bullet-height: 12px !important;
  --swiper-pagination-bottom: -20px;
}
body {
  top: 0 !important;
}
.skiptranslate {
  display: none !important;
}
.v-main {
  background-color: var(--background);
  padding-top: 72px !important;
  color: var(--darkText);
}
.fontFamily {
  font-size: 20px;
}
.content {
  padding-left: 50px;
  padding-right: 50px;
}
::-webkit-scrollbar {
  width: 7px;
  display: none;
}
::-webkit-scrollbar-track {
  margin-top: 78px;
  margin-bottom: 156px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar);
  border-radius: 20px;
}
.v-lazy-image {
  opacity: 0;
  transition: opacity 0.6s linear;
}
.v-lazy-image-loaded {
  opacity: 1;
}
.v-overlay__content > .v-list::-webkit-scrollbar {
  width: 15px;
  display: block;
}
.v-overlay__content > .v-list::-webkit-scrollbar-thumb {
  background-color: var(--light);
  border: solid;
  border-width: 4px;
  border-color: white;
  margin: 0;
}
.v-overlay__content > .v-list::-webkit-scrollbar-track {
  margin: 0;
  background-color: white;
}
#vue-simple-context-menu {
  background-color: #282828;
  color: white;
  min-width: 250px;
}
.vue-simple-context-menu__item {
  color: white !important;
  font-size: 12px;
}
.vue-simple-context-menu__item:hover {
  background-color: var(--pop) !important;
  color: white !important;
}
.vue-simple-context-menu__item > span {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
@media (prefers-color-scheme: light) {
  #vue-simple-context-menu {
    background-color: var(--light);
    color: white;
  }
  .vue-simple-context-menu__item {
    color: black !important;
  }
}
</style>
