const colors = {
  actions: {
    async getColors(context) {
      return await context.dispatch("getRequest", {
        api: "colors",
      });
    },
  },
};

export default colors;
