<template>
  <v-app-bar class="pb-2 pr-2" color="charcoal" app>
    <v-app-bar-title>
      <router-link class="routerLink" v-on:click="closeAny()" to="/">
        <v-img width="150px" class="mt-2" aspect-ratio="10:2" :src="logo" />
      </router-link>
    </v-app-bar-title>
    <div style="display: flex" v-if="!$vuetify.display.mobile">
      <div v-for="(item, index) in navItems" :key="index" class="mr-5">
        <v-card class="card" color="charcoal" v-if="item.sections?.length">
          <v-btn
            class="pr-2 mt-2"
            :active="dialog[index] || active(item)"
            v-on:click="openHeader(index, !dialog[index])"
          >
            {{ item.title }}
            <v-icon>
              {{ dialog[index] ? "mdi-menu-up" : "mdi-menu-down" }}
            </v-icon>
          </v-btn>
        </v-card>
        <v-card class="card" color="charcoal" v-else>
          <v-btn class="px-4 mt-2" :to="item.button_link">
            {{ item.title }}
          </v-btn>
        </v-card>
        <v-dialog
          v-model="dialog[index]"
          content-class="dialog ma-0"
          open-delay="200"
          hide-overlay
          transition="dialog-top-transition"
          style="z-index: 1000"
        >
          <dropdown @close="dialog[index] = false" :item="item" />
        </v-dialog>
      </div>
    </div>
    <span v-if="$vuetify.display.mobile">
      <v-app-bar-nav-icon class="mr-5 mt-2" @click="$emit('toggleSidebar')" />
    </span>
  </v-app-bar>
</template>

<script>
import Dropdown from "./Dropdown.vue";
import { mapGetters } from "vuex";

export default {
  name: "HeaderView",
  components: {
    Dropdown,
  },
  data() {
    return {
      dialog: [],
    };
  },
  computed: {
    ...mapGetters(["navigation", "logo", "parent"]),
    navItems() {
      if (process.env.VUE_APP_TYPE === "WEB")
        return [
          ...this.navigation,
          {
            button_link: "/contact",
            title: "Contact",
          },
        ];
      return this.navigation;
    },
  },
  watch: {
    navigation() {
      this.dialog = this.navItems.map(() => false);
    },
    "$route.path"() {
      this.closeAny();
    },
  },
  methods: {
    hover(index) {
      this.dialog = this.dialog.fill(false);
      this.dialog[index] = true;
    },
    closeAny() {
      this.dialog.fill(false);
    },
    openHeader(index, open) {
      this.closeAny();
      if (open) this.dialog[index] = true;
    },
    active(item) {
      const scroll = this.$route.query.scroll ? "/" : "";

      return !!item.sections.find((section) => {
        if (
          this.$route.params.id == section.button_link ||
          this.$route.path == `${section.link}${scroll}` ||
          this.parent == section.button_link
        )
          return true;
        else return false;
      });
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 0 !important;
}
.routerLink {
  font-size: 22px;
  text-decoration: none;
  color: inherit;
}

.v-btn {
  font-size: 16px;
}

.v-toolbar {
  z-index: 1001;
}

.v-btn--active {
  background-color: var(--pop) !important;
}

.v-btn:hover {
  background-color: var(--pop) !important;
}

.v-card {
  box-shadow: none !important;
}
.v-list {
  box-shadow: none !important;
  background-color: var(--light) !important;
}
.v-overlay--active {
  backdrop-filter: blur(1px);
  background: rgb(0 0 0 / 0.2);
}
:deep() .dialog {
  top: 72px;
  width: 100%;
  max-width: 100%;
  left: 0;
}
:deep() .v-overlay__scrim {
  top: 72px !important;
}
</style>
