const contact = {
  actions: {
    async submitContactForm(context, body) {
      return await context.dispatch("postRequest", {
        api: "quote/submit/contact_form/submitter",
        body,
      });
    },
    async getReasons(context) {
      return await context.dispatch("getRequest", {
        api: "web/reason",
      });
    },
    async submitQuoteRequest(context, { body, files }) {
      let form = new FormData();
      form.append("file_count", files.length);
      files.forEach((file, index) => {
        const fileObject = new File(
          [file[0]],
          file[0]?.name.replace(/[^0-9a-z. ]/gi, ""),
          {
            type: file[0]?.type,
          }
        );
        form.append(`file_${index}`, fileObject);
      });
      Object.keys(body).forEach((key) => {
        form.append(key, body[key]);
      });

      return await context.dispatch("formRequest", {
        api: "quote/submit/intake_quote/submitter",
        form,
      });
    },
    async getIntakeLists(context) {
      return await context.dispatch("getRequest", {
        api: "web/intake",
      });
    },
  },
};

export default contact;
