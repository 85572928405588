<template>
  <v-dialog v-model="sidebarModel" transition="slide-x-reverse-transition">
    <section-sidebar @close="sidebarModel = false" class="sidebar" />
  </v-dialog>
</template>

<script>
import SectionSidebar from "@/components/app/SectionSidebar.vue";
export default {
  components: { SectionSidebar },
  name: "SidebarView",
  props: {
    sidebar: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    sidebarModel: {
      get() {
        return this.sidebar && process.env.VUE_APP_TYPE === "WEB";
      },
      set(value) {
        this.$emit("closeSidebar", value);
      },
    },
  },
};
</script>

<style scoped>
.sidebar {
  position: static !important;
  border-right-style: none !important;
}
:deep() .v-overlay__scrim {
  top: 72px !important;
}
:deep() .v-overlay__content {
  margin: 0 !important;
  margin-top: 72px !important;
  width: fit-content !important;
  position: fixed;
  top: 0;
  right: 0;
}
</style>
