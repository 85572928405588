const footer = {
  state() {
    return {
      map: {},
      warehouseMap: {},
      contactInfo: {},
      warehouseInfo: {},
      icons: {},
    };
  },
  getters: {
    map(state) {
      try {
        return localStorage.getItem("map") != null
          ? JSON.parse(localStorage.getItem("map"))
          : state.map;
      } catch (error) {
        return state.map;
      }
    },
    warehouseMap(state) {
      try {
        return localStorage.getItem("warehouseMap") != null
          ? JSON.parse(localStorage.getItem("warehouseMap"))
          : state.warehouseMap;
      } catch (error) {
        return state.warehouseMap;
      }
    },
    contactInfo(state) {
      try {
        return localStorage.getItem("contactInfo") != null
          ? JSON.parse(localStorage.getItem("contactInfo"))
          : state.contactInfo;
      } catch (error) {
        return state.contactInfo;
      }
    },
    warehouseInfo(state) {
      try {
        return localStorage.getItem("warehouseInfo") != null
          ? JSON.parse(localStorage.getItem("warehouseInfo"))
          : state.warehouseInfo;
      } catch (error) {
        return state.warehouseInfo;
      }
    },
    icons(state) {
      try {
        return localStorage.getItem("icons") != null
          ? JSON.parse(localStorage.getItem("icons"))
          : state.icons;
      } catch (error) {
        return state.icons;
      }
    },
  },
  mutations: {
    setMap(state, map) {
      state.map = map.contact;
      state.warehouseMap = map.warehouse;
    },
    setContactInfo(state, contactInfo) {
      state.contactInfo = contactInfo;
    },
    setWarehouseInfo(state, warehouseInfo) {
      state.warehouseInfo = warehouseInfo;
    },
    setIcons(state, icons) {
      state.icons = icons;
    },
  },
  actions: {
    async getMap(context) {
      context
        .dispatch("getRequest", {
          api: "map",
        })
        .then((res) => {
          context.commit("setMap", res.data);
          if (res.useCookies) {
            localStorage.setItem("map", JSON.stringify(res.data.contact));
            localStorage.setItem(
              "warehouseMap",
              JSON.stringify(res.data.warehouse)
            );
          }
        });
    },
    async getContactInfo(context) {
      context
        .dispatch("getRequest", {
          api: "contact",
        })
        .then((res) => {
          context.commit("setContactInfo", res.data.contact);
          context.commit("setWarehouseInfo", res.data.warehouse);
          if (res.useCookies) {
            localStorage.setItem(
              "contactInfo",
              JSON.stringify(res.data.contact)
            );
            localStorage.setItem(
              "warehouseInfo",
              JSON.stringify(res.data.warehouse)
            );
          }
        });
    },
    async getIcons(context) {
      context
        .dispatch("getRequest", {
          api: "icons",
        })
        .then((res) => {
          context.commit("setIcons", res.data.icons);
          if (res.useCookies) {
            localStorage.setItem("icons", JSON.stringify(res.data.icons));
          }
        });
    },
  },
};

export default footer;
