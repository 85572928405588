const pages = {
  state() {
    return {
      parent: null,
      suggested: [],
    };
  },
  getters: {
    parent(state) {
      return state.parent;
    },
    suggested(state) {
      return [
        process.env.VUE_APP_TYPE === "WEB"
          ? {
              id: -1,
              reference: "/intake",
              icon: "mdi-email-outline",
              title: "Get A Quote",
            }
          : {
              id: -1,
              reference: "/box-form",
              icon: "mdi-locker-multiple",
              title: "Build Box",
            },
        ...state.suggested,
      ];
    },
  },
  mutations: {
    setParent(state, parent) {
      state.parent = parent;
    },
    setSuggested(state, suggested) {
      state.suggested = suggested;
    },
    clearSuggested(state) {
      state.suggested = [];
    },
  },
  actions: {
    async page(context, page_id) {
      return await context
        .dispatch("getRequest", {
          api: `page/${page_id}`,
        })
        .then((res) => {
          if (res.success) {
            context.commit("setSuggested", res.data.links);
            context.commit("setParent", res.data.parent);
          }
          return res;
        });
    },
    clearParent(context) {
      context.commit("setParent", null);
    },
    clearSuggested(context) {
      context.commit("clearSuggested");
    },
  },
};

export default pages;
