import { createStore } from "vuex";

// Util
import api from "@/stores/util/api";
import cookies from "@/stores/util/cookies";
import display from "@/stores/util/display";

// Web
import pages from "@/stores/web/pages";
import navigation from "@/stores/web/navigation";
import footer from "@/stores/web/footer";
import languages from "@/stores/web/languages";
import images from "@/stores/web/images";
import colors from "@/stores/web/colors";

// Modules
import contact from "@/stores/modules/contact";
import builder from "@/stores/modules/builder";
import builder_form from "@/components/kit-forms-submodule/builder_form.js";
import contact_info from "@/components/kit-forms-submodule/contact_info.js";

const modules =
  process.env.VUE_APP_TYPE === "WEB"
    ? [
      contact,
    ] : [
      builder,
      builder_form,
      contact_info,
    ];

const store = createStore({
  modules: {
    // Utils
    api,
    cookies,
    display,

    // Web
    pages,
    navigation,
    footer,
    languages,
    images,
    colors,

    // Modules
    ...modules
  },
});

export default store;
