<template>
  <div class="container">
    <v-list
      :style="`height: ${height - footerPosition}px; overflow-y: overlay`"
      class="mx-3 py-0"
      open-strategy="single"
      v-model:opened="opened"
    >
      <v-list-item to="/">
        <p class="font-weight-bold">HOME</p>
      </v-list-item>
      <v-list-group
        v-for="(menuItem, index) in navigation"
        :key="menuItem.title"
        :value="index"
      >
        <template v-slot:activator="{ props }">
          <v-list-item :active="false" v-bind="props">
            <p class="font-weight-bold">
              {{ menuItem.title.toUpperCase() }}
            </p>
          </v-list-item>
        </template>
        <v-hover
          v-for="section in menuItem.sections"
          :key="section.title"
          close-delay="100"
        >
          <template v-slot="{ props, isHovering }">
            <v-list-item
              :href="`${section?.link?.includes('http') ? section.link : ''}`"
              :to="`${section?.link?.includes('http') ? '' : section.link}`"
              class="subsection"
              v-bind="props"
              :active="
                section.open && !section.children?.find(({ open }) => open)
              "
              :append-icon="
                section.children?.length
                  ? isHovering || section.open || $vuetify.display.mobile
                    ? 'mdi-chevron-up'
                    : 'mdi-chevron-down'
                  : ''
              "
            >
              <p class="pl-2 font-weight-bold">
                {{ section.title.toUpperCase() }}
              </p>
            </v-list-item>
            <v-list-group
              :value="
                isHovering || section.open || $vuetify.display.mobile
                  ? index
                  : null
              "
            >
              <v-list-item
                v-for="child in section.children"
                :key="child.title"
                :href="`${child?.link?.includes('http') ? child.link : ''}`"
                :to="`${child?.link?.includes('http') ? '' : child.link}`"
                class="subsection child"
                v-bind="props"
                :active="child.open"
              >
                <div style="display: flex">
                  <v-icon icon="mdi-minus" class="mx-3" />
                  <p class="font-weight-bold">
                    {{ child.title.toUpperCase() }}
                  </p>
                </div>
              </v-list-item>
            </v-list-group>
          </template>
        </v-hover>
      </v-list-group>
      <v-list-item to="/contact">
        <p class="font-weight-bold">CONTACT</p>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SectionSidebar",

  data() {
    return {
      footerPosition: 0,
      footerHeight: 150,
      height: window.innerHeight - 62,
      opened: [],
    };
  },

  mounted() {
    const footer = document.querySelector(".v-footer").getBoundingClientRect();
    this.footerHeight = footer.height + 16;
    if (this.$vuetify.display.mobile) this.footerPosition = 0;
    else if (footer.bottom < window.innerHeight + this.footerHeight)
      this.footerPosition =
        window.innerHeight + this.footerHeight - footer.bottom + 15;
    this.$nextTick(() => {
      window.addEventListener("resize", this.resize);
      window.addEventListener("scroll", this.scrollVisible);
    });
  },

  created() {
    this.open();
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.resize);
    window.removeEventListener("scroll", this.scrollVisible);
  },

  watch: {
    navigation() {
      this.open();
    },
    parent() {
      this.open();
    },
    $route() {
      this.open();
      this.$emit("close");
    },
  },

  computed: {
    ...mapGetters(["navigation", "parent"]),
  },
  methods: {
    resize() {
      this.height = window.innerHeight - 62;
    },
    scrollVisible() {
      const footer = document
        .querySelector(".v-footer")
        .getBoundingClientRect();
      const footerBottom = footer.bottom;
      this.footerHeight = footer.height;
      if (
        !this.$vuetify.display.mobile &&
        footerBottom < window.innerHeight + this.footerHeight
      )
        this.footerPosition =
          window.innerHeight + this.footerHeight - footerBottom + 15;
      else this.footerPosition = 0;
    },
    open() {
      const scroll = this.$route.query.scroll ? "/" : "";

      this.navigation.forEach((element, index) => {
        element.sections.forEach((section) => {
          if (
            this.$route.params.id == section.button_link ||
            this.$route.path == `${section.link}${scroll}`
          ) {
            section.open = true;
            this.opened = [index];
          } else {
            section.open = false;
          }
          section?.children?.forEach((child) => {
            if (this.$route.params.id == child.id) {
              child.open = true;
              section.open = true;
              this.opened = [index];
            } else {
              child.open = false;
            }
          });
        });
      });
    },
  },
};
</script>

<style scoped>
.container {
  width: 270px;
  min-width: 270px;
  position: sticky;
  top: 72px;
  align-self: flex-start;
  background-color: var(--background);
  border-right-style: solid;
  border-right-width: 1px;
  border-color: var(--light);
  z-index: 20;
}
.v-list-item {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  font-size: 14px;
}
.subsection {
  font-size: 13px;
  background-color: var(--light);
  padding-left: 16px !important;
}
.subsection:hover {
  border-left-style: solid;
  border-left-width: 4px;
  padding-left: 12px !important;
  border-left-color: var(--pop);
}
.child {
  background-color: #c7c7c7;
  padding-left: 16px !important;
}
.v-list-item--active {
  border-left-style: solid;
  border-left-width: 4px;
  padding-left: 12px !important;
  border-left-color: var(--pop);
}
.v-list {
  background-color: var(--background);
}
::-webkit-scrollbar-thumb {
  background: transparent;
}
</style>
