import { createRouter, createWebHistory } from "vue-router";
import store from "@/stores/store.js";

const web = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/BuilderPage.vue"),
  },
  {
    path: "/page/:id/:title?",
    name: "builderPage",
    component: () => import("@/views/BuilderPage.vue"),
  },
];

const modules =
  process.env.VUE_APP_TYPE === "WEB"
    ? [
        {
          path: "/intake",
          name: "intakePage",
          component: () => import("@/views/Intake.vue"),
        },
        {
          path: "/contact",
          name: "contact",
          component: () => import("@/views/Contact.vue"),
        },
      ]
    : [
        {
          path: "/box-form",
          name: "box-form",
          component: () => import("@/views/BoxForm.vue"),
        },
      ];

const routes = [...web, ...modules];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
  routes,
});

router.beforeEach(async (to) => {
  store.dispatch("clearSuggested");
  store.dispatch("clearParent");

  if (to.matched.length === 0) return "/";
});

export default router;
