import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { loadFonts } from "./plugins/webfontloader";
import { register } from "swiper/element/bundle";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "@/stores/store.js";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import vue3dLoader from "vue-3d-loader";
import { createMetaManager } from "vue-meta";
import { createVuetify } from "vuetify";
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import VueSimpleContextMenu from "vue-simple-context-menu";
import "vue-simple-context-menu/dist/vue-simple-context-menu.css";
import VueGtag from "vue-gtag";
import VueSignaturePad from "vue-signature-pad";

import LogRocket from "logrocket";

if (process.env.VUE_APP_LOG_ROCKET_TOKEN) {
  LogRocket.init(process.env.VUE_APP_LOG_ROCKET_TOKEN);
}

register();

loadFonts();

axios.defaults.headers.common = {
  Authorization: `Bearer ${process.env.VUE_APP_API_TOKEN}`,
  Accept: "application/json",
};

const buildApp = async () => {
  const res = await store.dispatch("getColors");
  const colors = {};
  res.data.colors.forEach((color) => {
    colors[color.key] = color.value;
  });
  const vuetify = createVuetify({
    theme: {
      defaultTheme: "customLight",
      themes: {
        customLight: {
          dark: false,
          colors: colors,
        },
      },
    },
  });
  createApp(App)
    .use(router)
    .use(vuetify)
    .use(VueAxios, axios)
    .use(vue3dLoader)
    .use(store)
    .use(createMetaManager())
    .use(VueSignaturePad)
    .use(VueGoogleMaps, {
      load: {
        key: `${process.env.VUE_APP_GOOGLE_MAP_KEY}`,
      },
    })
    .use(
      VueGtag,
      {
        config: {
          id: process.env.VUE_APP_GOOGLE_ANALYTICS_PROPERTY_ID,
        },
      },
      router
    )
    .component("vue-simple-context-menu", VueSimpleContextMenu)
    .mount("#app");
};

buildApp();
